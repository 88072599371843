body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #EEF0F2;
  font-family: 'Overpass Mono', monospace;
}
html, body, #root {
  height: 100%;
}
.App {
  padding: 0 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.Main {
  flex: 1;
}
.Result {
  font-size: 24px;
  margin-top: 20px;
  line-height: 38px;
  color: #333;
}
.Footer {
  font-family: "Helvetica neue", Helvetica, Arial, sans-serif;
  padding: 20px;
  margin-left: -20px;
  margin-right: -20px;
  font-size: 14px;
  color: #444;
  line-height: 1.6em;
}
.Input {
  position: relative;
}
.Input-element {
  appearance: none;
  border-radius: 5px;
  border: solid 2px #999;
  box-shadow: 0 20px 6px -17px rgba(0,0,0,.3);
  box-sizing: border-box;
  font-size: 18px;
  height: 44px;
  margin-top: 20px;
  outline: none;
  padding: 10px 40px 10px 15px;
  transition: border-color .3s ease;
  width: 100%;
}
.Input-element::-webkit-input-placeholder {
  line-height: 28px;
}
.Input-element:focus {
  border-color: #02A9EA;
}
.Input-clear {
  appearance: none;
  background: #0000001a;
  border-radius: 15px;
  border: 0;
  box-shadow: none;
  height: 30px;
  padding: 0;
  position: absolute;
  right: 9px;
  transition: background .1s ease;
  top: 27px;
  width: 30px;
}
.Input-clearIcon {
  fill: #fff;
}
.Input-clear:focus {
  outline: none;
  background: #02A9EA;
}
.Word {
  opacity: 0;
  animation: fadeIn .3s forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}